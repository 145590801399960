<template>
  <div v-if="isNexos" class="float-right">
    <div class="d-flex align-items-center text-primary" style="height: 2rem">
      <b-link 
        :to="itemTypeRoute" 
        class="ml-1"
      >
        <feather-icon
          v-b-tooltip.hover
          :title="$t('tooltips.widget-actions.add')"
          icon="PlusIcon"
          variant="primary"
          size="20"
        />
      </b-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CreateNewButton',
  props: {
    itemType: { type: String, required: true },
    type: { type: String },
    isFullScreen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
    };
  },
  computed: {
    itemTypeRoute() {
      if (this.itemType === 'community-members') {
        return { name: this.itemType, params: { type: this.type } };
      }
      if (this.itemType === 'entity') {
        return { name: this.itemType, params: { typeKey: this.type } };
      }
      if (this.itemType === 'people') {
        return { name: this.itemType, params: { typeKey: this.type } };
      }
      if (this.itemType === 'events' && this.type) {
        return { name: this.itemType, query: { display: this.type } };
      }
      if (this.itemType === 'challenges' && this.type) {
        return { name: this.itemType, query: { display: this.type } };
      }
      return { name: this.itemType };
    },
    isNexos() {
      return process.env.VUE_APP_CUSTOMER === 'nexos';
    },
  },
  methods: {
    location() {
      const routeName = this.itemTypeRoute.name;
      return this.$router.push({ name: routeName, query: { openCreateModal: 'true' } });
    },
  },
};
</script>
<style scoped>
#search-tooltip {
  cursor: pointer;
}
.create-item {
  cursor: pointer;
}
</style>
